import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import imgTeam from "../assets/img_teams.png";
import imgIcashly from "../assets/icashly_cover.png";
import icashlyLogo from "../assets/icashly_logo.png";
import { getUrl } from "aws-amplify/storage";

import "../css/Home.css";

import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();

  const [videoHome, setVideoHome] = useState("");

  const downloadFile = async () => {
    const getUrlResult = await getUrl({
      path: "public/video.mp4",
    });
    setVideoHome(getUrlResult.url.toString());
  };
  useEffect(() => {
    downloadFile();
  });

  return (
    <div className="home-wrapper">
      <div className="container">
        <div className="d-flex justify-content-center">
          <video
            src={videoHome}
            width="900"
            height="550"
            autoPlay
            muted
            loop
            playsInline
          />
        </div>
        <div className="d-flex justify-content-center">
          <h1 className="text-center">Inspire Innovation</h1>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <p className="subtitle">{t("main.home.t1_support")}</p>
        </div>
        <div className="d-lg-flex justify-content-center text-center mt-3 pt-5 mb-3 pb-3">
          <div className="px-3 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">
              {t("main.home.finance")}
            </h4>
            <p className="text-start">{t("main.home.finance_desc")}</p>
          </div>
          <div className="px-3 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">AI</h4>
            <p className="text-start">{t("main.home.ai_desc")}</p>
          </div>
          <div className="px-3 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">
              {t("main.home.digital_services_title")}
            </h4>
            <p className="text-start">{t("main.home.digital_services_desc")}</p>
          </div>
        </div>
        <div className="d-lg-flex justify-content-center text-center mt-3 mb-5 pb-3">
          <div className="px-3 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">Energy & utilities</h4>
            <p className="text-start">{t("main.home.energy_desc")}</p>
          </div>
          <div className="px-3 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">Manufacturing</h4>
            <p className="text-start">{t("main.home.manufactoring_desc")}</p>
          </div>
          <div className="px-3 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">Automotive</h4>
            <p className="text-start">{t("main.home.automotive_desc")}</p>
          </div>
        </div>
        <div className="pb-5 mb-5 d-flex justify-content-center">
          <Link className="button-disc" to={"/services"}>
            {t("main.scopri")}
          </Link>
        </div>
      </div>
      <div className="img-teams">
        <img src={imgTeam} width="100%" height="auto" alt="Andromeda team" />
      </div>
      <div className="container">
        <div className="mt-5 pt-5 pb-5 d-lg-flex icashly-home-wrapper justify-content-center">
          <div className="col-lg-5 pe-3 me-3 icashly-wrapper-img">
            <img src={imgIcashly} width="100%" alt="iCashly" />
          </div>
          <div className="icashly-logo-wrapper col-lg-7 ps-5 ms-4">
            <p>{t("main.home.icashly_desc")}</p>
            <div className="d-lg-flex justify-content-center mt-5 pt-3">
              <img src={icashlyLogo} width="400" alt="iCashly logo" />
            </div>
          </div>
        </div>
        <div className="pb-5 d-flex justify-content-center">
          <Link className="button-disc" to={"/products"}>
            {t("main.scopri")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
